
import { Options, Vue } from "vue-class-component";

import Page from "@views/page/components/page.vue";
import PageRequest from "@/dataSource/api/pagination-helper/lib/PageRequest";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";
import EnumLogType from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogType";
import EnumLogLevel from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogLevel";
import GetTextLogDetailResponse from "@/dataSource/api/logger-micro-service/services/models/response/GetAllProjectLogGroupsResponse";

@Options<TextLogsDetailPage>({
  components: {
    Page,
  },
  props: { slotProps: {} },
})
export default class TextLogsDetailPage extends Vue {
  readonly slotProps = {};
  pagination: PageRequest;
  requestId: string = "";
  item: GetTextLogDetailResponse = null;

  isLoading = false;
  filterModel: FilterModel = new FilterModel();
  filterModelView: FilterModel = null;

  error1 = {
    isSuccess: true,
    errorMessage: "",
  };
  error2 = {
    isSuccess: true,
    errorMessage: "",
  };

  getUtc = getUtc;

  async mounted() {
    this.requestId = String(this.$route.params.id);
    await this.getLogData();
    this.isLoading = false;
  }

  getLocalStorageInfo() {
    if (localStorage.contentLogsRequestId) {
      this.requestId = localStorage.getItem("contentLogsRequestId");
      this.getLogDetails();
      this.getLogData();
    } else {
      this.$router.back();
    }
  }

  async getLogDetails() {
    this.isLoading = true;
    let request;
    try {
      request = await this.$api.LogGetterService.getTextLogDetailAsync(
        `"${this.requestId}"`
      );
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error1.isSuccess = false;
      this.error1.errorMessage = error;
      this.isLoading = false;
    }

    if (request) this.item = request;
  }

  async getLogData() {
    this.isLoading = true;
    this.item = (await this.$api.LogGetterService.getTextLogDetailAsync(
      `"${this.requestId}"`
    )) as any;
  }

  getLogType(type: EnumLogType) {
    return EnumLogType[type];
  }
  getLogLevel(level: EnumLogLevel) {
    return EnumLogLevel[level];
  }
}
